<template>
    <div class="advert">
        <div class="advert-main">
            <router-link v-if="imgObj.link" :to="imgObj.link">
                <img :src="imgObj.src" :alt="imgObj.alt?imgObj.alt:''">
            </router-link>
            <img v-else :src="imgObj.src" :alt="imgObj.alt?imgObj.alt:''">
        </div>
    </div>
</template>
<script>
export default {
    name: 'Advert',
    props: {
        imgObj: {
            type: Object,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.advert {
    position: relative;
    z-index: 10;
    min-width: 1200px;
    padding: 20px 0;
    background-color: $white;
}
.advert-main {
    width: 1200px;
    margin: 0 auto;
}
</style>
